import EmployeeDTO from './EmployeeDTO';
import { TypeOfEntry } from './EntryTypeDTO';

export enum GENDER {
  UNKNOWN = -1,
  MALE = 1,
  FEMALE = 2,
}

export enum CERTIFICATE_TYPE {
  UNKNOWN = -1,
  PREMIERE_INCAPACITY = 0,
  PROLONGATION = 1,
  RECHUTE = 2,
  JOUR_SANS_CERTIF = 3,
}

export default interface CertificateDTO {
  employee: EmployeeDTO;
  employeeId: string;
  gender: GENDER;
  superior: string;
  hrManager: string;
  startDate?: any;
  endDate?: any;
  certifType: CERTIFICATE_TYPE;
  incapacityReason: string;
  authorizedExit: boolean | number;
  doctorName: string;
  doctorAddress: string;
  urlCertificate: string;
  shouldBeChecked: boolean;
  completionDay: Date;
  isCheckRequestLaunched: boolean;
  checkRequestReason: string;
  entryType: TypeOfEntry;
  commentForDoctor?: string;
  commentForMedicheck?: string;
}
