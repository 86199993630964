import './style.css';
import React, { useEffect, useState } from 'react';
import Box from '@components/box';
import Button from '@components/button';
import Stepper from '@components/stepper';
import TranslationView from '@components/translation';
import { useHistory, useParams } from 'react-router-dom';
import { useCertificate } from '../../components/context/certificateContext';
import CertificateDTO from '@dtos/CertificateDTO';
import CertificateService from '@services/CertificateService';
import BusyIndicator from '@components/busy-indicator';
import { useConfiguration } from '@components/congifuration-context/ConfigurationContext';
import { LANGUAGE } from '@dtos/EmployeeDTO';
import { useTranslation } from '@components/translation-context/TranslationContext';
import { useEntryTypes } from '@components/entry-types-context/EntryTypeContext';
import { TypeOfEntry } from '@dtos/EntryTypeDTO';
import { toast } from 'react-toastify';

const Summary = () => {
  // ATTRIBUTES
  const { id } = useParams<any>();
  const history = useHistory();
  const { language } = useTranslation();
  const { certificate } = useCertificate();
  const [isBusy, setIsBusy] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [showError, setShowError] = useState(false);
  const { configuration } = useConfiguration();
  const { currentEntryType } = useEntryTypes();
	const { translate } = useTranslation();

  // FUNCTIONS
  async function handleOnClick() {
    try {
      if (accepted && currentEntryType !== undefined) {
        setIsBusy(true);
        // Send the certificate
        const certificateDTO: CertificateDTO = {
          ...certificate,
          entryType: currentEntryType,
        };

        const response = await createCertificate(certificateDTO);
        if (response.status === 200) {
          history.push(`/employee/${id}/success`);
        } else {
          toast.warning(response.data, {
            hideProgressBar: true,
            autoClose: 2000,
          });
        }
        setIsBusy(false);
      } else {
        setShowError(true);
      }
    } catch (e) { 
      toast.warning(translate("toastGenericError"), {
        hideProgressBar: true,
        autoClose: 2000,
      });
      setIsBusy(false);
    }
  }

  async function createCertificate(certificate: CertificateDTO) {
      certificate.employeeId = id;
      if (language == 'fr') {
        certificate.employee.language = LANGUAGE.FR;
      } else {
        certificate.employee.language = LANGUAGE.NL;
      }
      certificate.gender = parseInt(certificate.gender as any);
      certificate.authorizedExit =
        certificate.authorizedExit === 1 ? true : false;
      return await CertificateService.post(certificate, id);
  }

  function handleAccepted() {
    setAccepted((prevState) => !prevState);
    setShowError(false);
  }

  function formatDate(date?: Date) {
    if (date) {
      let year = date.getFullYear();

      let month: any = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }

      let day: any = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }

      return `${day}/${month}/${year}`;
    }

    return '/';
  }

  //Render
  return (
    <BusyIndicator isBusy={isBusy}>
      <Box>
        <Box type='boxinside'>
          <h3 className='title-summary'>
            <TranslationView>summaryTitle</TranslationView>
          </h3>

          <div className='subtitle-summary'>
            <TranslationView>summarySubTitle</TranslationView>
          </div>

          <div className='summary-form'>
            <span className='summary-info-title'>
              <TranslationView>summaryFullName</TranslationView>
            </span>{' '}
            <span className='summary-info'>
              {`${certificate.employee.firstName}`}{' '}
              {`${certificate.employee.lastName}`}
            </span>{' '}
            <br />
            <span className='summary-info-title'>
              <TranslationView>summaryPhoneNumber</TranslationView>
            </span>{' '}
            <span className='summary-info'>
              {' '}
              {`${certificate.employee.phoneNumber}`}
            </span>{' '}
            <br />
            <span className='summary-info-title'>
              <TranslationView>summaryAdresse</TranslationView>
            </span>{' '}
            <br />{' '}
            <span className='summary-info'>
              {' '}
              {`${certificate.employee.address}`}
            </span>{' '}
            <br />
            <span className='summary-info-title'>
              <TranslationView>summaryStartDate</TranslationView>
            </span>{' '}
            <span className='summary-info'>
              {' '}
              {`${formatDate(certificate.startDate)}`}
            </span>{' '}
            <br />
            <span className='summary-info-title'>
              <TranslationView>summaryEndDate</TranslationView>
            </span>{' '}
            <span className='summary-info'>
              {' '}
              {`${formatDate(certificate.endDate)}`}
            </span>{' '}
            <br />
          </div>

          <div className='summary-opt-in-container'>
            <div onClick={handleAccepted} className='summary-opt-in'>
              {accepted && <div className='summary-opt-in-clicked' />}
            </div>

            <div className='summary-opt-in-body'>
              <TranslationView>opt-in-body</TranslationView>
              <a
                href='https://mycertificatestorage.blob.core.windows.net/privacy/file.pdf'
                target='_blank'
              >
                <TranslationView>opt-in-body-link</TranslationView>
              </a>
              .
            </div>
          </div>

          <div
            className='errorSummary'
            id='summaryError'
            style={{ display: showError ? 'block' : 'none' }}
          >
            <TranslationView>checkboxError</TranslationView>
          </div>

          <div className='summary-buttons'>
            <Button type='valider' onClick={handleOnClick}>
              confirmNSend
            </Button>

            <Button type='retour' onClick={history.goBack}>
              retourPlaceholder
            </Button>
          </div>
        </Box>
      </Box>
      <Stepper
        step={(configuration?.screens?.length ?? 7) - 2}
        steps={configuration?.screens?.length}
      />
    </BusyIndicator>
  );
};

export default Summary;
