import { getCompanyIdByName } from '@utils/index';

let errors: string[] = [];

function config() {
	const access_token = localStorage.getItem('ACCESS_TOKEN');
	return { headers: { Authorization: `bearer ${access_token}` } };
}

async function getCompanyId() {
	const companyName = window.location.origin.split('//')[1].split('.')[0]; //first split to remove https second to decompose the url and get the name
	const company = await getCompanyIdByName('medicheck');
	return company.id;
}

async function execute(service: Promise<any>) {
	try {
		return await service;
	} catch (e) {
		if (e?.response?.status === 401) {
			localStorage.clear();
			window.location.pathname = '/employee';
		} else {
			return e?.response;
		}
	}
}

const API_URL = process.env.REACT_APP_RESOURCE_SERVER;

export default { errors, getCompanyId, execute, config, API_URL };
